<template>
  <div class="infoBar" v-if="open === true">
    <div class="infoBar__wrapper">
      <div class="infoBar__wrapperLeft">
        <div class="infoBar__firstBlock">
          {{ $t("We are changing for you") }}
        </div>
        <div class="infoBar__secondBlock">{{ $t("InfoBar description") }}</div>
        <div class="infoBar__buttonsWrapper">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeCnNvZkLDcs5PKWDKzoPQBFNkFvF2GtfSPdSXiri-FQN5O7A/viewform?usp=sf_link" target="_blank">
            <Button
              text="Share your opinion"
              variant="outline-primary"
              class="infoBar__button"
              id="infoBar__buttonGoToSurvey"
            />
          </a>
        </div>
      </div>
      <div class="infoBar__closeIcon">
        <b-icon-x-lg @click="handleClick" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/components/base/Button";
import { ref } from "vue";

export default {
  name: "InfoBar",

  components: { Button },

  setup() {
    const open = ref(true);
    const handleClick = () => {
      open.value = !open.value;
    };

    return {
      open,
      handleClick,
    };
  },
};
</script>

<style scoped></style>